<template>
  <div class="container impressum">
    <div class="col-12">
      <h6 class="text-blue">{{ $t("impressumPage.imprint") }}</h6>
      <p class="mb-5">
        {{ $t("impressumPage.companyInformation") }}
      </p>
      <h6 class="text-blue">
        {{ $t("impressumPage.responsibleContent") }}
      </h6>
      <p class="mb-5">{{ $t("impressumPage.reinerHornberger") }}</p>
      <h6 class="text-blue">{{ $t("impressumPage.businessResident") }}</h6>
      <div class="mb-5">
        <p>IHGP RECHT GmbH Rechtsanwaltsgesellschaft</p>
        <p>Oranienstraße 155</p>
        <p>10969 Berlin</p>
        <p>{{ $t("impressumPage.germany") }}</p>
      </div>
      <div class="mb-5">
        <p>{{ $t("phone") }}: +49 30 6110186-0</p>
        <p>Fax: +49 30 6110186-28</p>
        <p>E-Mail: kanzlei@ihgp-recht.de</p>
      </div>
      <!-- <h6 class="text-blue">{{ $t("impressumPage.serviceTimes") }}</h6>
      <ul>
        <li>
          <span>{{ $t("impressumPage.monday") }}</span>
          <span>09:00 – 17:00</span>
        </li>
        <li>
          <span>{{ $t("impressumPage.tuesday") }}</span>
          <span>09:00 – 17:00</span>
        </li>
        <li>
          <span>{{ $t("impressumPage.wednesday") }}</span>
          <span>{{ $t("impressumPage.datesArrangement") }}</span>
        </li>
        <li>
          <span>{{ $t("impressumPage.thursday") }}</span>
          <span>09:00 – 17:00</span>
        </li>
        <li>
          <span>{{ $t("impressumPage.friday") }}</span>
          <span>09:00 – 17:00</span>
        </li>
      </ul>
      <p class="mb-5">
        {{ $t("impressumPage.outsideServiceHours") }}
      </p> -->
      <h6 class="text-blue">
        {{ $t("impressumPage.internetPresence") }}
      </h6>
      <div>
        <p>IHGP STEUER GmbH Steuerberatungsgesellschaft</p>
        <p>Oranienstraße 155</p>
        <p>10969 Berlin</p>
      </div>
      <div class="mb-5">
        <p>{{ $t("phone") }}: +49 30 6110186-0</p>
        <p>Fax: +49 30 6110186-28</p>
        <p>E-Mail: kanzlei@ihgp-steuer.de</p>
      </div>
      <p class="mb-3">
        {{ $t("impressumPage.internetPresenceOneText") }}
      </p>
      <p class="mb-3">
        {{ $t("impressumPage.internetPresenceTwoText") }}
      </p>
      <p class="mb-5">
        {{ $t("impressumPage.internetPresenceThreeText") }}
      </p>
      <p class="mb-5">{{ $t("impressumPage.internetPresenceFourText") }}</p>
      <p class="mb-5">
        {{ $t("impressumPage.internetPresenceFiveText") }}
      </p>
      <h6 class="text-blue">{{ $t("impressumPage.competentChamber") }}</h6>
      <p class="mb-5">
        {{ $t("impressumPage.competentChamberText") }}
      </p>
      <div>
        <div class="mb-5">
          <p>{{ $t("impressumPage.berlinTax") }}</p>
          <p>{{ $t("impressumPage.publicLaw") }}</p>
          <p>Wichmannstraße 6</p>
          <p>10787 Berlin</p>
        </div>
        <div class="mb-5">
          <p>{{ $t("impressumPage.phone") }}: 030 889261-0</p>
          <p>{{ $t("impressumPage.fax") }}: 030 889261-10</p>
          <p>E-Mail: info@stbk-berlin.de</p>
          <p>Internet: www.stbk-berlin.de</p>
        </div>
      </div>
      <h6 class="text-blue">
        {{ $t("impressumPage.taxConsultant") }}
      </h6>
      <ul>
        <li>{{ $t("impressumPage.taxConsultanty") }}</li>
        <li>{{ $t("impressumPage.implementingTaxConsultanty") }}</li>
        <li>{{ $t("impressumPage.codeTaxConsultanty") }}</li>
        <li>{{ $t("impressumPage.ordinanceTaxConsultanty") }}</li>
      </ul>
      <p>
        {{ $t("impressumPage.homePageLink") }}
      </p>
      <h6 class="text-blue mb-5 mt-5">{{ $t("impressumPage.disclaimer") }}</h6>
      <h6 class="text-dark-blue mb-3">
        {{ $t("impressumPage.liabilityContents") }}
      </h6>
      <p class="mb-5">
        {{ $t("impressumPage.liabilityContentsText") }}
      </p>
      <h6 class="text-dark-blue mb-3">
        {{ $t("impressumPage.liabilityForLinks") }}
      </h6>
      <p class="mb-3">
        {{ $t("impressumPage.liabilityForLinksOneText") }}
      </p>
      <p class="mb-5">
        {{ $t("impressumPage.liabilityForLinksTwoText") }}
      </p>
      <h6 class="text-blue">{{ $t("impressumPage.copyright") }}</h6>
      <p>
        {{ $t("impressumPage.copyrightText") }}
      </p>
      <p class="mt-5">
        <!-- {{ $t("impressumPage.euText", {Url: `<a href="http://ec.europa.eu/consumers/odr" target="_blank">http://ec.europa.eu/consumers/odr</a>`}) }} -->
        {{ $t("impressumPage.euText") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.impressum {
  padding: 160px 0px;
  text-align: justify;

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}
</style>